var app;
(function (app) {
    var imageview;
    (function (imageview) {
        'use strict';
        var DirectiveController = /** @class */ (function () {
            DirectiveController.$inject = ["$uibModal"];
            function DirectiveController($uibModal) {
                // this.openUimodal();
                var _this = this;
                this.$uibModal = $uibModal;
                this.openMod = function (data) {
                    _this.openUimodal(data);
                };
            }
            DirectiveController.prototype.openUimodal = function (data) {
                //  console.log('this.$uibModal',this.$uibModal)
                var modalInstance = this.$uibModal.open({
                    animation: true,
                    windowClass: 'ng-show',
                    controllerAs: 'modal',
                    controller: /* @ngInject */ ["$scope", "$timeout", function ($scope, $timeout) {
                        //modal-image
                        $scope.imgLoaded = false;
                        /// injecting $image couses some clash and wont show modal popup
                        $scope.closeM = function () {
                            modalInstance.close();
                            console.log('modal closed!');
                        };
                        // console.log('$element',$element)
                        $scope.imageUrl = data.url;
                        var tester = new Image();
                        tester.src = $scope.imageUrl;
                        // onload
                        tester.onload = function () {
                            $scope.imgLoaded = true;
                            $timeout(function () {
                                $scope.$apply();
                                console.log('image loaded!!');
                            });
                        };
                        // error
                        tester.onerror = function () {
                            $scope.imgLoaded = null;
                            console.log('image not laoded!!', $scope.imageUrl);
                        };
                    }],
                    size: 'lg',
                    //modalTemplate: '<div class="modal modal-width-override" ng-transclude></div>',
                    template: modalTmplate(data),
                    // controller: 'ModalInstanceCtrl',
                    //  controllerAs: '$ctrl',
                    //  size: size,
                    // appendTo: parentElem,
                    resolve: {
                        items: function () {
                            return 'this is the text';
                        }
                    }
                });
            };
            return DirectiveController;
        }());
        angular.module('app.imageview', []);
        angular.module('app.imageview')
            .directive("imageview", ["$rootScope", "$parse", function ($rootScope, $parse) {
            return {
                template: imageTemplate(),
                // ngModel: '=',
                scope: {
                    url: '=',
                    desc: '=',
                    group: "=",
                },
                controller: DirectiveController,
                link: function (scope, elem, attr, ctrl) {
                    if (!ctrl) {
                        return;
                    }
                    var model = $parse(attr.url);
                    scope.uri = model(scope);
                    var model = $parse(attr.desc);
                    scope._desc = model(scope);
                    var groupName = scope.group;
                    var sets = {
                        "1x1": $rootScope.IPATH + "/media/" + groupName + "/1x1/" + scope.uri,
                        "full": $rootScope.IPATH + "/media/" + groupName + "/full/" + scope.uri,
                        "download": "./download/" + groupName + "/full/" + scope.uri
                    };
                    var _url = sets["1x1"]; //$rootScope.IPATH+'/'+scope.url;
                    //scope.url = '_url';
                    scope.nUrl = _url;
                    scope.dlink = sets.download;
                    console.log('scope.imageIndex', scope.imageIndex);
                    // console.log('scope.url is', scope.url);
                    elem.find('.downloadme').bind('click', function (e) {
                    });
                    elem.find('.viewme, .image-item').bind('click', function () {
                        ctrl.openMod({ url: sets['full'], desc: scope._desc, dlink: sets.download });
                    });
                    elem.bind('click', function (event) {
                        //
                        //console.log('ctrl', ctrl)
                    });
                } ////
            };
        }]);
    })(imageview = app.imageview || (app.imageview = {}));
})(app || (app = {}));
function imageTemplate() {
    return "\n        <span class=\"text text-white\">\n            <a  class=\"viewme\" href=\"#\"><i class=\" fa-eye fa m-2\"></i></a>\n            <a class=\"downloadme\" ng-href=\"{{dlink}}\" download target=\"_blank\"><i class=\" fa-arrow-circle-down fa m-2\"></i></a>\n        </span>     \n        <img ng-src=\"{{nUrl}}\" alt=\"\" class=\"p-1 ng-show border w image-item\">\n    ";
}
function modalTmplate(obj) {
    return "\n        <div class=\"gallery-modal\">\n            <div class='container'>\n                    <div class='row justify-content-center'>\n                        <div class='col'>\n                            <span class=\"caption\">\n                            <div ng-show=\"imgLoaded===false || imgLoaded===null\" class=\"ng-animate\">\n                                <state-preloader></tate-preloader>\n                            </div>\n\n                            <img ng-src=\"" + obj.url + "\" alt=\"\" ng-click=\"closeM()\" class=\"p-1 ng-animate modal-image\" ng-show=\"imgLoaded===true\" />\n\n                                <div class=\"d-flex justify-content-around justify-content-between text-white w-100\">\n                                    <div class='desc pl-1 pr-3 py-3 w-75 align-self-start'>" + obj.desc + "</div>\n                                    \n                                    <div class=\"align-self-end w-25 \"><a download target=\"_blank\" \n                                    ng-href=\"" + obj.dlink + "\"><i class=\" fa-arrow-circle-down fa mx-2\"></i></a></div>    \n                                </div>                    \n                                                      \n                            </span>\n                        </div>\n                    </div>\n            </div> \n         </div>   \n    ";
}
